import React from "react"
import { graphql } from "gatsby"
import { useIntl, Link } from "gatsby-plugin-intl"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const IconsBusiness = ({ data }) => {
  const intl = useIntl()
  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: "category.icons" })} />
      <section className="w-full md:w-3/4 flex flex-col px-3">
        <h3 className="font-semibold mb-3 text-xl leading-tight sm:leading-normal">
          {intl.formatMessage({ id: "icons.business_title" })}(
          {data.allSvgItemsJson.totalCount}
          {intl.formatMessage({ id: "query.items" })})
        </h3>
        <div className="images grid grid-cols-4 gap-2 sm:grid-cols-5 md:grid-cols-7 md:gap-3 mb-8">
          {data.allSvgItemsJson.edges.map(({ node }, index) => (
            <div
              key={index}
              className="image bg-white rounded border border-gray-400 hover:shadow"
            >
              <Link to={"/icons/download?q=" + node.name}>
                <img
                  src={"/images/" + node.cat + "/" + node.name + ".svg"}
                  alt={node.tag}
                  className="p-2"
                  loading="lazy"
                ></img>
              </Link>
            </div>
          ))}
        </div>
      </section>
    </Layout>
  )
}
export default IconsBusiness

export const query = graphql`
  query {
    allSvgItemsJson(
      filter: { sub_cat: { eq: "business" }, cat: { eq: "icons" } }
      sort: { order: DESC, fields: id }
    ) {
      edges {
        node {
          id
          cat
          name
          tag
        }
      }
      totalCount
    }
  }
`
